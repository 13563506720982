import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShoppingBadge from "../../CommmonComponents/ShoppingBadge";
import { Button, Typography } from "@mui/material";

const AdminNavigation = () => {
  const win = window.sessionStorage;
  const navigate = useNavigate();

  const ShowOrder = () => {
    // Navigate to AdminOrders with "NewOrders" as the orderType
    navigate("/AdminOrders", { state: { orderType: "NewOrders" } });
  };

  const handleLogout = () => {
    win.clear();
    navigate("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/Dashboard">FoodHut</Link>
          <ul className="navbar-nav">
            {/* Only New Orders */}
            <li className="nav-item px-2">
              <Button onClick={ShowOrder}>New Orders</Button>
            </li>
            <li className="nav-item px-2">
              <Link to="/PaymentMenthods">Payment Methods</Link>
            </li>
          </ul>
          <Typography>
            <span className="font-bold">Welcome:</span> {win.getItem("fname")}{" "}
            {win.getItem("lname")}
          </Typography>
          <Typography>
            <Link to="/AdminAccount" className="font-bold">
              My Account
            </Link>
          </Typography>
          <Button onClick={handleLogout}>Logout</Button>
        </div>
      </nav>
    </>
  );
};

export default AdminNavigation;