import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Footer from '../Customer/Footer';
import AdminNavigation from './AdminNavigation';

const drawerWidth = 240;

interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false); // Initially closed

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <div style={{ display: 'flex', position: 'relative' }}>
        {/* Hamburger Menu */}
        <Drawer
          variant="persistent"
          open={drawerOpen}
          sx={{
            width: drawerOpen ? drawerWidth : 0, // Set width to 0 when closed
            flexShrink: 0,
            transition: 'width 0.3s ease', // Smooth width transition
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              display: drawerOpen ? 'block' : 'none', // Hide drawer content when closed
            },
          }}
        >
          {/* Drawer content */}
          <List>
            <ListItem button>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>

        {/* Main Content */}
        <div
          style={{
            flexGrow: 1,
            marginLeft: drawerOpen ? `${drawerWidth}px` : '0', // Adjust margin for content when drawer is open/closed
            transition: 'margin-left 0.3s ease',
          }}
        >
          <div className="container">
            {/* Hamburger Icon */}
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>

            {/* Admin Navigation */}
            <div className="row">
              <AdminNavigation />
            </div>

            {/* Page Content */}
            {props.children}

            <Divider orientation="horizontal" flexItem />

            {/* Footer */}
            <div className="row">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;