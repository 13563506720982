import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CustomerLayout from "./Components/Layouts/Customer/CustomerLayout";
import { ShoppingCartContext } from "../../utilities/Context/ShoppingCartContext";
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import AppLayout from './Components/Layouts/App/AppLayout';
import SigninModal from '../app/GeneralComponents/SigninModal';

// Helper function to group items by businessId
const groupByBusiness = (items: ShoppingCart[]) => {
    return items.reduce((groups, item) => {
        const { businessId } = item;
        if (!groups[businessId]) {
            groups[businessId] = [];
        }
        groups[businessId].push(item);
        return groups;
    }, {} as Record<number, ShoppingCart[]>);
};

const ShoppingCartPage = () => {
    const { shoppingcart, dispatch } = useContext(ShoppingCartContext);
    const [isSigninModalOpen, setIsSigninModalOpen] = useState(false);
    const [checkoutBusinessId, setCheckoutBusinessId] = useState<number | null>(null);  
    const navigate = useNavigate();    
    const win = window.sessionStorage;

    const userIdString = win.getItem("userid");
    const userId = userIdString ? parseInt(userIdString) : 0;  

    const openSigninModal = () => setIsSigninModalOpen(true);
    const closeSigninModal = () => setIsSigninModalOpen(false);

    const handleSignInSuccess = () => {
        closeSigninModal();
        if (checkoutBusinessId !== null) {
            handleCheckOut(checkoutBusinessId); // Proceed with checkout after signing in
            navigate("/Checkout");
        }
    };

    // Check if the cart is empty
    if (shoppingcart.length === 0) {
        return (
            <CustomerLayout>
                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                    <h2>Shopping Cart Empty</h2>
                    <p>Your shopping cart is currently empty. Please add items to your cart.</p>
                    <Button 
                        variant="contained" 
                        color="primary"
                        component={Link} 
                        to={`/Home`}
                    >
                        Go to Home
                    </Button>
                </div>
            </CustomerLayout>
        );
    }

    // Group items by businessId
    const groupedCartItems = groupByBusiness(shoppingcart);

    // Handle checkout for a specific business
    const handleCheckOut = (businessId: number) => {
        if (!userId) {
            // If user is not logged in, open the signin modal
            setCheckoutBusinessId(businessId);
            openSigninModal();
            return;
        }
        // If user is logged in, proceed with checkout
        dispatch({ type: 'SET_SELECTED_BUSINESS', businessId: businessId });
        navigate("/Checkout");
        
    };

    // Handle adding an item to the cart
    const handleAddItem = (item: ShoppingCart) => {
        dispatch({ type: 'ADD_TO_CART', item });
    };

    // Handle reducing an item quantity in the cart
    const handleReduceItem = (item: ShoppingCart) => {
        dispatch({ type: 'REDUCE_CART', item });
    };

    // Handle removing an item from the cart
    const handleRemoveItem = (item: ShoppingCart) => {
        dispatch({ type: 'REMOVE_FROM_CART', item });
    };

    return (
        <AppLayout>
            {Object.keys(groupedCartItems).map(businessId => {
                const items = groupedCartItems[parseInt(businessId)];

                // Calculate total price for the current business
                const totalPrice = items.reduce((total, item) => total + item.quantity * item.price, 0);

                return (
                    <div key={businessId} style={{ marginBottom: '2rem' }}>
                        <h2>Business ID: {businessId}</h2>
                        <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Unit Price</TableCell>
                                        <TableCell align="right">Total Price</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map(cart => (
                                        <TableRow
                                            key={cart.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{cart.name}</TableCell>
                                            <TableCell align="right">{cart.quantity}</TableCell>
                                            <TableCell align="right">{cart.price}</TableCell>
                                            <TableCell align="right">{cart.quantity * cart.price}</TableCell>
                                            <TableCell align="right">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary"
                                                    onClick={() => handleAddItem(cart)}
                                                >
                                                    Add
                                                </Button>
                                                <Button 
                                                    variant="outlined" 
                                                    color="secondary"
                                                    onClick={() => handleReduceItem(cart)}
                                                >
                                                    Reduce
                                                </Button>
                                                <Button 
                                                    variant="outlined" 
                                                    color="error"
                                                    onClick={() => handleRemoveItem(cart)}
                                                >
                                                    Remove
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p><strong>Total: MK {totalPrice.toFixed(2)}</strong></p>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={() => handleCheckOut(parseInt(businessId))}
                            >
                                Check Out
                            </Button>
                        </div>
                    </div>
                );
            })}
            <SigninModal
                open={isSigninModalOpen}
                handleClose={closeSigninModal}
                onSignInFeedback={handleSignInSuccess}
            />
        </AppLayout>
    );
};

export default ShoppingCartPage;