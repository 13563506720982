import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import { useForm, SubmitHandler } from 'react-hook-form';
import ExtendedSearchForm from './Components/Forms/ExtendedSearchForm';
import AppLayout from './Components/Layouts/App/AppLayout';
import { Menu } from '../../domain/Types/Menu';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Box, Card, CardContent, CardMedia, Grid, IconButton, Rating, Typography } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import { ShoppingCartState } from '../../utilities/Context/ShoppingCartContext';
import AddToCartModal from '../app/GeneralComponents/AddToCartModal';


const ExtendedSearch = () => {

    const { reset } = useForm();

    const onSubmit: SubmitHandler<FormData> = async (data) => 
    { 
        console.log(data);
        reset();
    }
    
    interface FormData 
    {
        Offers: string;
        DeliveryFee: string;
        Under30min: string;
        Rating: string;
        Price: string;
        Dietary: string;
    }

    // Get location state using useLocation
    const location = useLocation();
    const { state } = location;
    
    // Effect to set menu items from location state when component mounts
    useEffect(() => {
        if (state && state.results) {
            setMenuItems(state.results);
        }
    }, [state]);    

  const [menuItems, setMenuItems] = useState<Menu[]>([]);
  const [foodItems, setfoodItems] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [openModal, setOpenModal] = useState(false); 
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null);   
  const win = window.sessionStorage;
  const { dispatch } = ShoppingCartState();


  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;    

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessname: menu.business.name
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
    setOpenModal(false);
  };  

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };  

  const FoodTile = ({ item }: { item: typeof foodItems[0] }) => {
    return (
      <Card
        sx={{
          borderRadius: '16px 16px 8px 8px',
        }}
      >
        <Box position="relative">
          <Badge
            badgeContent={`MK${item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            color="primary"
            sx={{
              position: 'absolute',
              top: 15,
              left: 30,
              zIndex: 1,
              padding: '0.5rem',
              borderRadius: '8px',
              backgroundColor: '#1976d2',
              color: 'white',
            }}
          />
          <IconButton
            aria-label="add to cart"
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              zIndex: 1,
              backgroundColor: '#ffffff',
            }}
            onClick={() => handleOpenModal(item)}
          >
            <AddShoppingCart />
          </IconButton>
          <CardMedia
            component="img"
            image={`https://app.foodhut.mw/Admin/public/images/${item.image_path}`}
            alt={item.name}
            sx={{
              height: 140,
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>
        <CardContent sx={{ padding: 1 }}>
          <Typography variant="h6" component="div" sx={{ marginBottom: 0.5 }}>
            {item.name}
          </Typography>
          <Rating value={item.average_rating} precision={0.5} readOnly sx={{ marginBottom: 0.5 }}/>
          <Box mt={0}>
            <Typography variant="h6" component="div" color="textPrimary" sx={{ marginBottom: 0.5 }}>
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="textSecondary"
              sx={{ textDecoration: 'line-through', marginBottom: 0 }}
            >
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <AppLayout>
            <Box className="search-results" sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>                              
                  {menuItems.length > 0 ? (
                      menuItems.map(item => (
                        <Grid item xs={12} sm={6} md={3} key={item.id}>  
                              <FoodTile item={item} />                    
                        </Grid>
                      ))
                  ) : (
                      <p>No results found</p>
                  )}
              </Grid>
            </Box>
            {selectedItem && (
      <AddToCartModal 
      open={openModal} 
      handleClose={handleCloseModal}
      selectedItem={selectedItem} 
      userId={userId}
/>  
      )}
</ AppLayout>
  )
}

export default ExtendedSearch